<template>
  <div class="w-full height-container-mapbox">
    <div id="mapbox"></div>
  </div>
</template>
<script>
import Mapbox from '@/mixins/map/mapbox'
import { mapGetters } from 'vuex'
import parcelle from '@/mixins/parcelle'

export default {
  data() {
    return {}
  },
  computed: {
    ...mapGetters({
      current_parcelle: 'parcelle/current_parcelle',
      parcelles_voisines: 'parcelle/parcelles_voisines',
      map: 'map/map',
      address: 'address/address',
    }),
  },
  mixins: [Mapbox, parcelle],
  mounted() {
    this.loadMap()
      ? this.loadDataToMap()
      : this.map.on('load', this.loadDataToMap)
  },
  methods: {
    loadDataToMap() {
      let own_parcelles = [this.current_parcelle, ...this.parcelles_voisines]
      let fc_own_parcelles = this.$turf.featureCollection(own_parcelles)
      this.fitBoundsGeojsonInMap(fc_own_parcelles)
      this.$emit('addMakers')
      this.addSourceLayersMultiGeojsonToMap(
        this.own_parcelles,
        this.green_parcelle_selected,
        this.source_layers_all_parcelle,
      )
    },
  },
  beforeDestroy() {
    this.map.off('load', this.loadDataToMap)
  },
}
</script>
<style></style>

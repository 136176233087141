import mapbox from '@/mixins/map/mapbox'
import { mapGetters } from 'vuex'
import axios from 'axios'
import helpers from '@/mixins/helpers'

export default {
  mixins: [mapbox, helpers],
  data() {
    return {
      types: [
        {
          label: 'Transports',
          keys: ['transport', 'transport_en_commun'],
          marker: 'transport',
          color: '#1578fe',
          icon: 'TransportIcon',
          subKeys: [
            {
              name: 'Bus',
              key: 'bus',
              marker: 'bus',
            },
            {
              name: 'Accès routier',
              key: 'route_rapide',
              marker: 'transport',
            },
            {
              name: 'Gare',
              key: 'gare',
              marker: 'gare',
            },
            {
              name: 'Parking',
              key: 'parking',
              marker: 'parking',
            },
            {
              name: 'Aéroport',
              key: 'aeroport',
              marker: 'airport',
            },
            {
              name: 'Vélo partage',
              key: 'velo_partage',
              marker: 'veloPartage',
            },
            {
              name: 'Auto partage',
              key: 'auto_partage',
              marker: 'autoPartage',
            },
            {
              name: 'Borne de charge',
              key: 'borne_recharge',
              marker: 'borneCharge',
            },
          ],
        },
        {
          label: 'Éducation',
          keys: ['education'],
          marker: 'education',
          color: '#69d8fe',
          icon: 'EducationIcon',
          subKeys: [
            {
              name: 'Crèche',
              key: 'creche',
              marker: 'creche',
            },
            {
              name: 'Maternelle',
              key: 'maternelle',
              marker: 'maternelle',
            },
            {
              name: 'Élémentaire',
              key: 'elementaire',
              marker: 'maternelle',
            },
            {
              name: 'Collège',
              key: 'college',
              marker: 'maternelle',
            },
            {
              name: 'Lycée général',
              key: 'lycee',
              marker: 'lyceeGenerale',
            },
            {
              name: 'Lycée professionnel',
              key: 'lycee_pro',
              marker: 'lyceePro',
            },
          ],
        },
        {
          label: 'Commerce',
          keys: ['publique'],
          marker: 'commerce',
          color: '#ed8f12',
          icon: 'CommerceIcon',
          subKeys: [
            {
              name: 'Supermarché',
              key: 'alimentation_generale',
              marker: 'commerce',
            },
            {
              name: 'Shopping',
              key: 'shopping',
              marker: 'shopping',
            },
          ],
        },
        {
          label: 'Loisir et tourisme',
          keys: ['publique'],
          marker: 'tourism',
          color: '#5c40a5',
          icon: 'TourismIcon',
          subKeys: [
            {
              name: 'Cinema',
              key: 'cinema',
              marker: 'cinema',
            },
            {
              name: 'Bar et café',
              key: 'bar_et_cafe',
              marker: 'barEtCafe',
            },
            {
              name: 'Restaurant',
              key: 'restaurant',
              marker: 'restaurant',
            },
            {
              name: 'Clubs',
              key: 'clubs',
              marker: 'club',
            },
            {
              name: 'Musée',
              key: 'musee',
              marker: 'musee',
            },
            {
              name: 'Theatre',
              key: 'theatre/opera',
              marker: 'theatre',
            },
            {
              name: 'Sport',
              key: 'sport_individuel',
              marker: 'sport',
            },
            {
              name: 'Sport collectif',
              key: 'sport_collectif',
              marker: 'sportCollectif',
            },
            {
              name: 'Hotel',
              key: 'hotel',
              marker: 'hotel',
            },
          ],
        },
        {
          label: 'Services publics et Santé',
          keys: ['publique'],
          marker: 'sante',
          color: '#7ed859',
          icon: 'HealthIcon',
          subKeys: [
            {
              name: 'Commissariat',
              key: 'commissariat',
              marker: 'securite',
            },
            {
              name: 'Gendarmerie',
              key: 'gendarmerie',
              marker: 'securite',
            },
            {
              name: 'Hôpital',
              key: 'urgences',
              marker: 'hospital',
            },
            {
              name: 'Pompiers',
              key: 'pompiers',
              marker: 'pompier',
            },
            {
              name: 'Médecin(s)',
              key: 'medecin',
              marker: 'doctor',
            },
            {
              name: 'Pharmacie',
              key: 'pharmacie',
              marker: 'pharmacy',
            },
            {
              name: 'personnes âgées',
              key: 'personnes_agees',
              marker: 'pharmacy',
            },
            {
              name: 'postes',
              key: 'postes',
              marker: 'pharmacy',
            },
          ],
        },
      ],
      old_markers: new Map(),
      line_route: {},
      driving: {},
      walking: {},
      profiles: ['driving', 'walking'],
      position_active: {
        subCat: null,
        marker: null,
        label: null,
        key: null,
      },
    }
  },

  methods: {
    getPolygonForMaxZoom(feature_collection_markers_parcelles, distanceByKm) {
      let enveloped = this.$turf.envelope(feature_collection_markers_parcelles)
      let own_parcelles = [this.current_parcelle, ...this.parcelles_voisines]
      let union_parcelles = this.$turf.union(...own_parcelles)

      var center_parcelles = this.$turf.pointOnFeature(union_parcelles)
      let buffered_center_parcelles = this.$turf.buffer(
        center_parcelles,
        distanceByKm,
      )

      let intersect_martinez = this.$martinez.intersection(
        enveloped.geometry.coordinates,
        buffered_center_parcelles.geometry.coordinates,
      )
      let polyToZoom = this.$turf.polygon(
        this.getCoordinates(intersect_martinez),
      )
      return polyToZoom
    },
    createIconForLocalisation(point) {
      var el = document.createElement('div')
      el.className = 'mapbox-marker'
      el.id = point.geometry.coordinates.toString()
      el.setAttribute('coords_poi', point.geometry.coordinates)
      el.setAttribute('marker', point.properties.marker)
      el.setAttribute('key', point.properties.key)
      el.setAttribute('label', point.properties.localisation_name)
      el.setAttribute('subCat', point.properties.subCat)
      el.style.backgroundImage = `url(/images/GU/markers/commodite/${point.properties.marker}.svg)`
      el.style.zIndex = 18

      let popup = new this.$mapboxgl.Popup({
        anchor: 'bottom',
      })

      let subCat = this.parseStringtoArray(point.properties.subCat)
        .toString()
        .replace(',', ', ')
      let labels = this.parseStringtoArray(point.properties.localisation_name)
      let div_labels = ''
      labels.forEach((label) => {
        div_labels = div_labels + `<p>${label}</p>`
      })
      let template = `<div class="w-60 h-[7rem] rounded-lg flex flex-col text-xs" style="z-index: 19;">
                        <div class="flex items-center p-4 text-promy-primary font-bold ">
                            <img src="/images/GU/markers/commodite/${point.properties.marker}-icon.svg" class="w-5 h-5 mr-2" />
                            ${subCat}
                        </div>
                        <div class="px-4 py-4 bg-promy-gray-50 text-promy-primary rounded-b-lg flex-1 flex items-center">${div_labels}</div>
                      </div>`
      popup.on('open', (e) => {
        this.customPopupButtonClose(e)
      })
      let marker = this.addCustomMarkerWithPopupInMap(
        template,
        point.geometry.coordinates,
        el,
        popup,
      ).marker
      return marker
    },
    changeIconMarker() {
      this.old_markers.forEach((marker) => {
        let el = marker.getElement()
        let marker_name = el.getAttribute('marker')
        if (el.getAttribute('key') !== this.position_active.key) {
          el.style.backgroundImage = `url(/images/GU/markers/commodite/${marker_name}.svg)`
          el.style.zIndex = 9
        } else if (el.getAttribute('key') === this.position_active.key) {
          el.style.backgroundImage = `url(/images/GU/markers/commodite/${marker_name}-active.svg)`
        }
      })
    },
    checkMarkerOpen(marker) {
      return (
        marker.getElement().getAttribute('key') !== this.position_active.key ||
        !this.position_active.key
      )
    },
    createIconForCluster(point_count, coords, clusterID) {
      var el = document.createElement('div')
      el.className = 'mapCluster'
      el.innerText = point_count
      let marker = new this.$mapboxgl.Marker(el).setLngLat(coords)
      marker.addTo(this.map)
      el.addEventListener('click', () => {
        this.map
          .getSource(this.source_and_layers_cluster[0])
          .getClusterExpansionZoom(clusterID, (err, zoom) => {
            if (err) return
            this.map.easeTo({
              center: coords,
              zoom: zoom,
            })
          })
      })
      return marker
    },
    clusteringMarkers() {
      if (this.map.getSource(this.source_and_layers_cluster[0])) {
        if (!this.map.isSourceLoaded(this.source_and_layers_cluster[0])) return
        var features = this.map.querySourceFeatures(
          this.source_and_layers_cluster[0],
        )
        let keep_markers = []
        for (let i = 0; i < features.length; i++) {
          if (features[i].properties.cluster) {
            if (
              this.old_markers.get('cluster_' + features[i].id) === undefined
            ) {
              this.old_markers.set(
                'cluster_' + features[i].id,
                this.createIconForCluster(
                  features[i].properties.point_count,
                  features[i].geometry.coordinates,
                  features[i].id,
                ),
              )
              keep_markers.push('cluster_' + features[i].id)
            } else {
              keep_markers.push('cluster_' + features[i].id)
            }
          } else {
            if (
              this.old_markers.get(features[i].properties.key) === undefined
            ) {
              let marker = this.createIconForLocalisation(features[i])
              this.old_markers.set(features[i].properties.key, marker)
              this.addListenerToMarkerLocalisation(marker.getElement())
              keep_markers.push(features[i].properties.key)
            } else {
              keep_markers.push(features[i].properties.key)
            }
          }
        }

        this.old_markers.forEach((marker, key, map) => {
          if (!keep_markers.includes(key) && this.checkMarkerOpen(marker)) {
            marker.remove()
            map.delete(key)
          }
        })
      }
    },
    addListenerToMarkerLocalisation(el) {
      let vm = this
      el.addEventListener('click', function (e) {
        let coords_marker = e.target.getAttribute('id').split(',')
        vm.changeIconMarker()
        vm.position_active.marker = e.target.getAttribute('marker')
        vm.position_active.label = e.target.getAttribute('label')
        vm.position_active.subCat = e.target.getAttribute('subCat')
        vm.position_active.key = e.target.getAttribute('key')
        vm.profiles.forEach((profile) => {
          vm.getDirection(profile, coords_marker).then((response) => {
            let route = response.data.routes[0]
            vm.line_route = vm.$turf.lineString(route.geometry.coordinates)
            if (profile === 'driving') vm.driving = route.legs[0]
            else vm.walking = route.legs[0]
            vm.add_layer_route_line(profile, coords_marker)
          })
        })
      })
    },
    add_layer_route_line(profile, coords_marker) {
      let cuurent_marker = this.$turf.point(coords_marker)
      let own_parcelles = [this.current_parcelle, ...this.parcelles_voisines]
      let feature_collection = this.$turf.featureCollection([
        cuurent_marker,
        ...own_parcelles,
        this.line_route,
      ])
      let enveloped = this.$turf.envelope(feature_collection)

      let source_layers =
        profile === 'driving'
          ? this.source_and_layers_route_driving
          : this.source_and_layers_route_walking
      let dash = profile === 'driving' ? false : true
      let color = profile === 'driving' ? this.green_light : this.orange
      this.removeSourceAndLayers(...source_layers)
      this.addGeojsonToMap(source_layers[0], this.line_route)
      this.addOutlineLayerToGeojson(
        source_layers[2],
        source_layers[0],
        color,
        dash,
      )
      if (profile === 'driving') {
        let padding_bounds = screen.width < 768 ? 100 : 130
        this.fitBoundsGeojsonInMap(enveloped, padding_bounds)
      }
    },
    getDirection(profile, end, start = [this.address.lat, this.address.long]) {
      return new Promise((resolve) => {
        axios
          .get(
            `${this.$mapbox_directions}${profile}/${start[0]},${start[1]};${end[0]},${end[1]}?geometries=geojson&overview=full&access_token=${this.$key_mapbox}`,
          )
          .then((response) => {
            resolve(response)
          })
          .catch(() => {
            this.toast('Commodités', 'itineraire non disponible', 'warning')
          })
      })
    },
    addSourceAndLayerForCluster(data, source_and_layers_cluster) {
      this.map.addSource(source_and_layers_cluster[0], {
        type: 'geojson',
        data: data,
        generateId: true,
        cluster: true,
        clusterMaxZoom: 15,
        clusterRadius: 50,
      })
      this.map.addLayer({
        id: source_and_layers_cluster[1],
        type: 'circle',
        source: source_and_layers_cluster[0],
        filter: ['==', source_and_layers_cluster[0], true],
        paint: {
          'circle-opacity': 0,
        },
      })
    },
  },

  computed: {
    ...mapGetters({
      current_parcelle: 'parcelle/current_parcelle',
      parcelles_voisines: 'parcelle/parcelles_voisines',
      map: 'map/map',
      address: 'address/address',
    }),
  },
}

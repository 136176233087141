<template>
  <div class="w-full relative flex-1" id="layout-map-and-tools">
    <div class="absolute top-8 left-12 z-30 lg:left-8">
      <AddressAlert
        class="lg:hidden"
        :uuid="address ? address.uuid: ''"
        :label="address.information.properties.label"
        :dept_region="address.information.properties.context"
        :canSeeIcon="checkIfCanAlertIconBeVisible()"
      />
    </div>
    <!-- This slot is used in Etude commerciale, Commodités & Urbanisme -->
    <div v-if="withToolBar" class="absolute top-8 right-12 z-30 lg:right-2">
      <transition name="slide">
        <pro-button
          @click="toggle"
          class="
            absolute
            right-0
            justify-between
            items-center
            mx-2
            mb-2
            md:rounded-full
            slideContent
          "
          :class="openToolBar ? 'right-64 lg:right-72 lg:mr-6' : ''"
          icon
          :outline="!openToolBar"
        >
          <span class="md:hidden">Outils</span>
          <OutilsIcon :color="openToolBar ? 'white' : '#10AAAF'" />
        </pro-button>
      </transition>
      <transition name="slide">
        <ToolBar v-show="openToolBar" @close="closeToolBar">
          <slot name="outils"></slot>
        </ToolBar>
      </transition>
    </div>
    <div class="absolute lg:bottom-0 top-0 lg:top-auto left-0 z-20 sm:mb-11">
      <slot name="left-side"></slot>
    </div>
    <div
      class="
        absolute
        desktop:top-8
        right-24
        transform
        z-10
        sm-ws:right-24 sm-ws:-translate-x-0
        lg:bottom-36 lg:right-8
        sm:bottom-28 sm:mb-8
      "
    >
      <slot name="actions-header"></slot>
    </div>

    <div
      class="absolute top-8 right-12 sm:right-4 z-10 slideContent"
      :class="{ 'top-24': withToolBar, 'right-80': withToolBar && openToolBar }"
    >
      <Tips />
    </div>
    <div
      class="
        absolute
        top-60
        right-12
        lg:left-4 lg:right-auto lg:top-10
        z-10
        slideContent
      "
      :class="withToolBar && openToolBar ? 'right-80' : ''"
    >
      <slot name="actions-middle">
        <DownloadAndSendSummary />
      </slot>
    </div>
    <div
      class="
        absolute
        bottom-12
        left-1/2
        transform
        -translate-x-1/2
        z-[31]
        w-auto
        lg:bottom-0 lg:w-full
      "
    >
      <slot name="footer-details-infos"> </slot>
      <pro-button
        class="
          hidden
          sm:block
          px-6
          py-3
          border-promy-green-350
          rounded-none
          w-full
          font-semibold
        "
        btn="primary"
        @click="revelezPotentiel"
      >
        Révélez le potentiel
      </pro-button>
    </div>
    <div
      class="
        absolute
        left-12
        bottom-12
        z-50
        lg:z-10 lg:bottom-36
        sm:bottom-28
        lg:left-4
        sm:mb-12
      "
    >
      <slot name="layers"> </slot>
    </div>
    <slot name="map"></slot>
    <ButtonsZoomInOut class="sm:bottom-40"   :class="openToolBar ? '!right-80 lg:!right-72 lg:!mr-6' : ''"></ButtonsZoomInOut>
  </div>
</template>
<script>
import DownloadAndSendSummary from './Actions/DownloadAndSendSummary.vue'
import { mapGetters, mapMutations } from 'vuex'

export default {
  props: {
    withToolBar: {
      type: Boolean,
      default: false,
    },
    initOpenToolBar: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      openToolBar: true,
    }
  },
  watch: {
    initOpenToolBar: {
      handler(is_open) {
        this.openToolBar = is_open
      },
      immediate: true,
    },
  },
  created() {
    if (window.screen.width <= 768) {
      this.openToolBar = false
    }
  },
  components: {
    DownloadAndSendSummary,
  },
  computed: {
    ...mapGetters({
      address: 'address/address',
    }),
  },
  methods: {
    ...mapMutations({
      toggleGuestModal: 'auth/TOGGLE_GUEST_MODAL',
    }),
    revelezPotentiel() {
      this.$router.push({
        name: 'RevelationSteps',
        query: { id_address: this.$route.params.id_address },
      })
    },
    checkIfCanAlertIconBeVisible() {
      return this.$route.name !== 'StreetView'
    },
    toggle() {
      this.openToolBar = !this.openToolBar
    },
    closeToolBar() {
      this.openToolBar = false
    },
  },
}
</script>
<style lang="scss">
.slideContent {
  transition: all 0.5s ease-in-out;
}
</style>

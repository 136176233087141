<template>
  <LayoutMapAndTools withToolBar v-if="isPOINotEmpty">
    <template #outils>
      <div class="space-y-6">
        <div class="space-y-4 pr-4">
          <ProCheckbox
            v-for="(type, index) in types"
            :key="index"
            :val="type"
            :id="type.marker"
            v-model="selected"
            @check="checking($event)"
          >
            <div class="flex items-center justify-center">
              <component :is="type.icon" color="#AFAFAF" class="w-5 h-5 mr-2" />
              <p class="md:text-sm text-[14px]">
                {{ type.label }} ({{ points_interet[type.marker] }})
              </p>
            </div>
          </ProCheckbox>
        </div>
        <Itirinary
          :driving="driving"
          :walking="walking"
          :position_active="position_active"
          class="mx-auto w-full"
        />
      </div>
    </template>
    <template #layers>
      <MapType class="md:bottom-12" :layers="layers" />
    </template>
    <template #map>
      <Map class="size-map ml-auto" @addMakers="addAllMarkers" />
    </template>
    <template #footer-details-infos>
      <div class="space-y-12 lg:space-y-0">
        <PointsInteret
          v-if="points_interet_loaded"
          class="w-[30rem] lg:w-full"
          :points_interet="points_interet"
        />
        <AddressAlert
          class="hidden lg:flex w-full"
          :label="address.information.properties.label"
          :dept_region="address.information.properties.context"
          :canSeeIcon="true"
        />
      </div>
    </template>
  </LayoutMapAndTools>
  <empty-state
    v-else
    class="h-4/5 mt-7 bg-transparent"
    :message="'Ces informations ne sont pas disponibles pour cette adresse'"
    :main_icon="'bot1'"
    classes_icon="!w-1/4 !h-1/4 sm:!w-48 sm:!h-48"
  />
</template>

<script>
import Map from './Map.vue'
import commodite from '@/mixins/commodite'
import LayoutMapAndTools from '../LayoutMapAndTools.vue'
import { mapGetters } from 'vuex'

export default {
  mixins: [commodite],
  components: {
    LayoutMapAndTools,
    Map,
  },

  data() {
    return {
      points_interet: {
        transport: 0,
        education: 0,
        commerce: 0,
        tourism: 0,
        sante: 0,
      },
      points_interet_loaded: false,
      points: [],
      multiPt: {},
      selected: [],
      layers: null,
      isPOINotEmpty: false,
    }
  },
  computed: {
    ...mapGetters({
      poi: 'address/getPOI',
      infos_commune: 'address/infos_commune',
    }),
  },

  created() {
    this.isPOINotEmpty = Object.keys(this.poi.data).length
    this.layers = [
      {
        label: 'Cadastre',
        image: 'Cadastre.svg',
        is_active: false,
        component: 'CadastreOfficiel',
        data: {
          code_insee: this.infos_commune.code_insee,
        },
      },
    ]
  },

  mounted() {
    this.map.on('render', this.updateMarkers)
  },

  watch: {
    selected(items) {
      this.addAllLocalisations(items)
    },
  },

  methods: {
    sliceOption() {
      if (!this.position_active.label) return
      let vm = this
      var filtered = this.selected.filter(function (el) {
        return el.label != vm.position_active.label
      })
      let marker_exist = filtered.find((el) => {
        return el.marker === vm.position_active.marker
      })

      if (!this.isExist(marker_exist)) {
        this.remove_add_layer_route_line()
        this.driving = {}
        this.walking = {}
        this.position_active = {
          subCat: null,
          marker: null,
          label: null,
          key: null,
        }
      }
    },
    remove_add_layer_route_line() {
      this.removeSourceAndLayers(...this.source_and_layers_route_driving)
      this.removeSourceAndLayers(...this.source_and_layers_route_walking)
    },
    addMarkersSelected(item) {
      let count_by_cat = 0
      item.keys.forEach((key) => {
        let localisations = this.getNestedObject(this.poi.data, key)
        if (this.isExist(localisations)) {
          for (let [key, value] of Object.entries(localisations)) {
            let localisation_exist = item.subKeys.find(
              (subKey) => subKey.key === key,
            )

            if (this.isExist(localisation_exist)) {
              value.forEach((infos, index) => {
                if (!infos.name) {
                  infos.name = 'N.C'
                }

                let coords = infos.coordonnee_gps
                  .split('(')[1]
                  .split(')')[0]
                  .split(' ')

                let feature_point = this.$turf.point(coords)
                feature_point.properties.key = key + '_' + index
                feature_point.properties.marker = item.marker
                feature_point.properties.subCat = []
                feature_point.properties.subCat.push(localisation_exist.name)
                feature_point.properties.subMarker = localisation_exist.marker
                feature_point.properties.localisation_name = []
                feature_point.properties.localisation_name.push(infos.name)
                feature_point.properties.coords = coords
                count_by_cat += 1
                this.points.push(feature_point)
              })
            }
          }
        }
      })
      this.points_interet[item.marker] = count_by_cat
      const distinct_point = this.points.reduce(
        (accumulator, current) =>
          accumulator.some((item, index) => {
            let is_find =
              item.properties.coords.join() === current.properties.coords.join()
            if (is_find) {
              accumulator[index].properties.localisation_name.push(
                ...current.properties.localisation_name,
              )
              accumulator[index].properties.subCat.push(
                ...current.properties.subCat,
              )
            }
            return is_find
          })
            ? accumulator
            : [...accumulator, current],
        [],
      )
      this.multiPt = this.$turf.featureCollection(distinct_point)
    },
    addCluster() {
      if (this.isExist(this.map.getLayer(this.source_and_layers_cluster[1]))) {
        this.map.removeLayer(this.source_and_layers_cluster[1])
        this.map.removeSource(this.source_and_layers_cluster[0])
      }

      if (this.multiPt.features) {
        let own_parcelles = [this.current_parcelle, ...this.parcelles_voisines]
        var feature_collection = this.$turf.featureCollection([
          ...this.multiPt.features,
          ...own_parcelles,
        ])
        let polyToZoom = this.getPolygonForMaxZoom(feature_collection, 1)

        this.addSourceAndLayerForCluster(
          this.multiPt,
          this.source_and_layers_cluster,
        )

        this.fitBoundsGeojsonInMap(polyToZoom, 80)
      }
    },
    updateMarkers() {
      this.clusteringMarkers()
      this.changeIconMarker()
    },
    addAllMarkers() {
      this.selected = this.types
    },
    addAllLocalisations(items) {
      this.points = []
      this.multiPt = []
      this.sliceOption()
      this.old_markers.forEach((marker) => {
        marker.remove()
      })

      items.forEach((item) => {
        this.addMarkersSelected(item)
      })
      this.points_interet_loaded = true

      this.addCluster()
      this.updateMarkers()
    },
  },
  beforeRouteLeave(to, from, next) {
    if (this.map) {
      this.map.off('render', this.updateMarkers)
    }
    this.old_markers.forEach((marker) => marker.remove())
    this.$destroy()
    next()
  },
}
</script>

<style>
.popup-marker {
  @apply bg-gray-50 p-5 rounded-lg;
}
.marker_cat {
  @apply bg-white cursor-pointer;
  width: 30px;
  height: 43px;
}
</style>
